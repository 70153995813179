import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';

import { CompleteInvitation } from '../../models/complete-invitation';
import { Login } from '../../models/login';
import { PasswordChange } from '../../models/password-change';
import { PasswordReset } from '../../models/password-reset';
import { RegisterConfirmation } from '../../models/register-confirmation';
import { UserSecret } from '../../models/user-secret';
import { AppUrlsConfig } from '../app-urls.config';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { CompleteInvitationMapper } from '../mappers/complete-invitation.mapper';
import { SuccessResponseDto } from '../mappers/dto/success-response.dto';
import { UserSecretDto } from '../mappers/dto/user-secret.dto';
import { LoginDataMapper } from '../mappers/login-data.mapper';
import { PasswordChangeMapper } from '../mappers/password-change.mapper';
import { RegisterConfirmationMapper } from '../mappers/register-confirmation.mapper';
import { ResetPasswordConfirmationMapper } from '../mappers/reset-password-confirmation.mapper';
import { ResetPasswordMapper } from '../mappers/reset-password.mapper';
import { UserSecretDataMapper } from '../mappers/user-secret-data.mapper';
import { NewEmailConfirmation } from '../../models/new-email-confirmation';
import { NewEmailConfirmationMapper } from '../mappers/new-email-confirmation.mapper';
import { buildHttpParams } from '../../utils/build-http-params';

/**
 * Performs CRUD operations for auth-related information.
 */
@Injectable({ providedIn: 'root' })
export class AuthApiService {
  private readonly apiUrlsConfig = inject(AppUrlsConfig);

  private readonly httpClient = inject(HttpClient);

  private readonly loginDataMapper = inject(LoginDataMapper);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly userSecretMapper = inject(UserSecretDataMapper);

  private readonly resetPasswordMapper = inject(ResetPasswordMapper);

  private readonly resetPasswordConfirmationMapper = inject(ResetPasswordConfirmationMapper);

  private readonly passwordChangeMapper = inject(PasswordChangeMapper);

  private readonly registerConfirmationMapper = inject(RegisterConfirmationMapper);

  private readonly completeInvitationMapper = inject(CompleteInvitationMapper);

  private readonly newEmailConfirmationMapper = inject(NewEmailConfirmationMapper);

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<UserSecret> {
    return this.httpClient.post<UserSecret>(
      this.apiUrlsConfig.auth.login,
      this.loginDataMapper.toDto(loginData),
    )
      .pipe(
        map(dto => this.userSecretMapper.fromDto(dto)),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.loginDataMapper,
        ),
      );
  }

  /**
   * Refresh user's secret.
   * @param secret Secret data.
   */
  public refreshSecret(secret: UserSecret): Observable<UserSecret> {
    return this.httpClient.put<UserSecretDto>(
      this.apiUrlsConfig.auth.refreshSecret,
      this.userSecretMapper.toDto(secret),
    )
      .pipe(
        map(refreshedSecret => this.userSecretMapper.fromDto(refreshedSecret)),
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }

  /**
   * Sends request to reset the password.
   * @param data Data for password reset.
   * @returns Success message.
   */
  public resetPassword(data: PasswordReset.Data): Observable<string> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.auth.resetPassword,
      this.resetPasswordMapper.toDto(data),
    )
      .pipe(
        map(() => `An email was sent to: ${data.email}`),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.resetPasswordMapper,
        ),
      );
  }

  /**
   * Confirms password reset and applies new passwords to the account.
   * @param data New passwords data.
   * @returns Success message.
   */
  public confirmPasswordReset(
    data: PasswordReset.Confirmation,
  ): Observable<string> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.auth.confirmPasswordReset,
      this.resetPasswordConfirmationMapper.toDto(data),
    )
      .pipe(
        map(() => `Your password has been changed`),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.resetPasswordConfirmationMapper,
        ),
      );
  }

  /**
   * Changes password of current user.
   * @param data Data required for password changing.
   */
  public changePassword(data: PasswordChange): Observable<void> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.userAuth.changePassword,
      this.passwordChangeMapper.toDto(data),
    )
      .pipe(
        map(() => undefined),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.passwordChangeMapper,
        ),
      );
  }

  /**
   * Confirm email after registration.
   * @param data Confirmation data.
   * @returns Success message.
   */
  public confirmRegistration(data: RegisterConfirmation): Observable<string> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.register.registerConfirm,
      this.registerConfirmationMapper.toDto(data),
    ).pipe(
      map(() => `An email has been successfully verified.`),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Confirm email and set password after invitation.
   * @param data Completion data.
   * @returns Success message.
   */
  public completeInvitation(data: CompleteInvitation): Observable<string> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.register.registerCompleteInvitation,
      this.completeInvitationMapper.toDto(data),
    ).pipe(
      map(() => `Your registration has been successfully completed.`),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Confirm new email.
   * @param data Confirmation data.
   */
  public confirmNewEmail(data: NewEmailConfirmation): Observable<void> {
    const params = buildHttpParams(this.newEmailConfirmationMapper.toDto(data));

    return this.httpClient.post<void>(
      this.apiUrlsConfig.auth.confirmNewEmail,
      null,
      { params },
    ).pipe(
      map(() => undefined),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
